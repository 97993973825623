<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form ref="form" @submit.prevent="handleSubmit(saveProject)" autocomplete="off">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ $t(modalTitle(name)) }} <strong>{{ $tc('menu.projects') }}</strong></span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">
					<SelectWithValidation class="mb-4" rules="required" :label="$tc('menu.groups')" v-model="form.group_id">
						<option v-for="g in groups" :value="g.id" :key="g.id">{{ g.name }}</option>
					</SelectWithValidation>

					<InputWithValidation class="mb-4" rules="required" :label="$tc('fields.name')" v-model="form.name" />
					<InputWithValidation class="mb-4" rules="required" :label="$tc('fields.location')" v-model="form.location" />

					<SelectMultiple :input_label="$tc('menu.commands', 2)" v-model="form.commands" :options="commands" track-by="name" label="name" />

					<SelectMultiple :input_label="$tc('labels.tech', 2)" v-model="form.stack" :options="stack" />

					<small class="modal-updated" v-if="form.updated_at">{{ $t('labels.last_change') }} {{ format(form.updated_at) }}</small>
				</div>
			</div>
			<Footer :loading="loading" />
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import SelectWithValidation from '@/components/inputs/SelectWithValidation'
import SelectMultiple from '@/components/inputs/SelectMultiple'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import Footer from '@/components/modals/Footer'
import '@/mixins/generic'

export default {
	components: {
		Footer,
		InputWithValidation,
		SelectWithValidation,
		SelectMultiple,
		ValidationObserver
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			permission: [],
			visible: false,
			form: {
				name: '',
				location: '',
				group_id: '',
				stack: [],
				commands: []
			},
			stack: ['Vue', 'React', 'Astro', 'Strap', 'Laravel', 'Node', 'Wordpress'],
			commands: [],
			groups: []
		}
	},
	methods: {
		async findAllGroups() {
            this.isOpening = true
            try {
                const response = await Api.get('groups/findAll')
                if (response.status === 200) {
                    this.groups = response.data
                    this.isOpening = false
                }
            } catch (e) {
                console.log(e)
            }
        },
		async findAllCommands() {
            this.isOpening = true
            try {
                const response = await Api.get('commands/findAll')
                if (response.status === 200) {
                    this.commands = response.data
                    this.isOpening = false
                }
            } catch (e) {
                console.log(e)
            }
        },
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`projects/findById/${this.id}`)
					if (response.status === 200) {
						const { data } = response
						this.form.name = data.name
						this.form.location = data.location
						this.form.group_id = data.group_id
						this.form.commands = data.commands
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
		async update() {
			try {
				this.loading = true

				let formData = new FormData()
				formData.append('_method', 'put')
				formData.append('name', this.form.name)
				formData.append('location', this.form.location)
				formData.append('group_id', this.form.group_id)

				this.form.commands.forEach((c) => {
                    formData.append('commands[]', c.id)
                })

				const response = await Api.post(`projects/update/${this.id}`, formData)
				const { status } = response
				if ([200, 201].includes(status)) {
					this.$emit('close')
					history.pushState({}, '', '/projects')
					successToast(this.$t('alerts.update.success', [this.$tc('menu.projects')]))
					eventHub.$emit('reload-projects')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					errorToast(this.$t('alerts.update.error', [this.$tc('menu.projects')]))
				}
			} finally {
				this.loading = false
			}
		},
		async store() {
			try {
				this.loading = true

				let formData = new FormData()
				formData.append('name', this.form.name)
				formData.append('location', this.form.location)
				formData.append('group_id', this.form.group_id)

				this.form.commands.forEach((c) => {
                    formData.append('commands[]', c.id)
                })

				const response = await Api.post('projects/store', formData)
				const { status } = response
				if ([200, 201].includes(status)) {
					this.$emit('close')
					history.pushState({}, '', '/projects')
					successToast(this.$t('alerts.create.success', [this.$tc('menu.projects')]))
					eventHub.$emit('reload-projects')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(this.$t('alerts.create.error', [this.$tc('menu.projects')]) + '<small>' + message + '</small>')
				}
			} finally {
				this.loading = false
			}
		},
		async saveProject() {
			this.name === 'New' ? await this.store() : await this.update()
		}
	},
	mounted() {
		this.findAllGroups()
		this.findAllCommands()
		this.findById()
	}
}
</script>
