<template>
	<div>
		<header class="modal-card-head">
			<h4 class="modal-card-title">
				<span>
					Selecione o arquivo
				</span>
			</h4>
		</header>
		<div class="modal-card-body modal-select modal-rounded">
			<ul>
				<li v-for="(e) in envs" :key="e.name">
					<a class="modal-select__item" @click="handleClick(e.name)">
						<span>{{ e.name }}</span>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import eventHub from '@/services/eventHub'

export default {
	name: 'SelectEnv',
	props: {
		id: {
			type: [Number, String],
			required: false
		},
		envs: {
			type: [Array],
			required: true
		}
	},
	methods: {
		handleClick(name) {
			this.$emit('close')
			eventHub.$emit('edit-env-project', { id: this.id, file: name })
		}
	}
}
</script>
