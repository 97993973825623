<template>
	<div>
		<header class="modal-card-head">
			<h4 class="modal-card-title">
				<span>
					{{ groupName }} > {{ projectName }} > {{ file }}
				</span>
			</h4>
		</header>

		<div class="modal-card-body modal-rounded">
			<form ref="form" @submit.prevent="submitForm" autocomplete="off">
				<b-loading :is-full-page="false" v-model="loading"></b-loading>

				<AceEditor v-model="content" theme="chaos" lang="text" height="400" @init="initEditor" />

				<b-button class="mt-3" native-type="submit" type="is-primary save" :loading="loading" rounded>Salvar</b-button>
			</form>
		</div>
	</div>
</template>

<script>
import Api from '@/services/api'
import AceEditor from 'vuejs-ace-editor'
import { errorToast, successToast } from '@/mixins/toast'

export default {
	name: 'EditorEnv',
	components: {
		AceEditor
	},
	props: {
		id: {
			type: [Number, String],
			required: false
		},
		file: {
			type: [String],
			required: true
		},
		groupName: {
			type: [String],
			required: true
		},
		projectName: {
			type: [String],
			required: true
		}
	},
	data() {
		return {
			loading: false,
			content: ''
		}
	},
	mounted () {
		// console.log(this.id)
		// console.log(this.file)
		// console.log(this.groupName)
		// console.log(this.projectName)

		this.getFile()
	},
	methods: {
		async getFile() {
			this.loading = true
			try {
				const response = await Api.post(`projects/getEnv/${this.id}`, { file: this.file })
				if (response.status === 200) {
					this.content = response.data.content
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async submitForm() {
			this.loading = true
			try {
				const response = await Api.post(`projects/saveEnv/${this.id}`, {
					file: this.file,
					content: this.content
				})

				if (response.status === 200) {
					this.content = ''
					this.$emit('close')
					successToast('Salvo com sucesso')
				}
			} catch (e) {
				console.log(e)
				errorToast('Erro ao salvar')
			} finally {
				this.loading = false
			}
		},
		initEditor () {
            require('brace/mode/text')
			require('brace/theme/chaos')
        }
	}
}
</script>
