<template>
	<Layout>
		<Header>
			<b-button v-if="permissionEnabled('projects', 'create')" type="is-primary create" rounded @click="modalCreate($event)">
				<svg-icon icon="plus" class="icon is-small"></svg-icon>
				<span>{{ $t('buttons.add') }}</span>
			</b-button>
		</Header>
		<section v-if="!errored" class="filter">
			<div class="filter__wrapper">
				<b-field :label="$t('labels.order')" label-position="on-border">
					<b-select v-model="order" :placeholder="$tc('fields.name')" @input="filterByOrder">
						<option selected value="name">{{ $tc('fields.name') }}</option>
						<option value="created_at">{{ $t('fields.date') }}</option>
					</b-select>
				</b-field>
				<b-field>
					<b-input :placeholder="$t('buttons.search')" type="search" icon-right="magnify" @input="findByName" v-model="name"></b-input>
				</b-field>
			</div>
		</section>
		<Error v-if="errored" :back="true" />
		<Results v-if="groups.length == 0 && !loading" />
		<div v-if="loading">
			<div v-for="pg in placeholder.groups" :key="pg" class="pt-1 mb-3">
				<b-skeleton width="150" height="20"></b-skeleton>
				<div class="columns is-multiline mt-1">
					<div v-for="pp in placeholder.projects" :key="pp" class="column is-12-mobile is-6-tablet is-4-desktop">
						<Placeholder />
					</div>
				</div>
			</div>
		</div>

		<div v-for="g in groups" :key="g.id">
			<h2 class="title is-size-5 is-semibold mt-5 mb-3" :style="{ 'color': g.color }">{{ g.name }}</h2>
			<div class="columns is-multiline">
				<div v-for="p in g.projects" :key="p.id" class="column is-12-mobile is-6-tablet is-4-desktop">
					<article class="block" :class="{ 'deploying': p.in_progress }" :style="{ 'border-left-color': g.color }" @click.self="modalEdit(p.id)">
						<div class="block__content" @click="modalEdit(p.id)">
							<h3 class="block__name" translate="no">{{ p.name }}</h3>
							<b-loading :is-full-page="false" :active="p.in_progress ? true : false" :can-cancel="true"></b-loading>
							<p class="block__email">{{ format(p.created_at) }} • {{ timeTo(p.created_at) }}</p>
						</div>
						<Trigger :id="p.id" />
					</article>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Icon from '@/components/Icon'
import Placeholder from '@/components/placeholders/Role'
import Trigger from '@/components/triggers/Project'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Modal from '@/components/modals/Project'
import eventHub from '@/services/eventHub'
import { create, update } from '@/mixins/modal'
import Api from '@/services/api'
import { responsive } from '@/mixins/responsive'
import { errorToast, successToast } from '@/mixins/toast'
import SelectEnv from '@/components/modals/SelectEnv'
import EditorEnv from '@/components/modals/EditorEnv'
import { ModalProgrammatic as ModalEnv } from 'buefy'
import { mapGetters } from 'vuex'
import mixinApp from '@/mixins/app'

export default {
	name: 'projects',
	mixins: [
		responsive,
		mixinApp
	],
	components: {
		Layout,
		Header,
		Placeholder,
		Trigger,
		Error,
		Results,
		'svg-icon': Icon
	},
	data() {
		return {
			loading: true,
			placeholder: {
				groups: 2,
				projects: 2
			},
			order: 'name',
			name: '',
			errored: false,
			projects: [],
			groups: []
		}
	},
	methods: {
		async findAll() {
			this.groups = []
			this.loading = true
			try {
				const response = await Api.get('projects/findAll')
				if (response.status === 200) {
					this.groups = response.data
				}
			} catch (e) {
				console.log(e)
				errorToast(this.$t('alerts.load_error'))
			} finally {
				this.loading = false
			}
        },
		async filterByOrder() {
			this.groups = []
			this.loading = true
			try {
				const response = await Api.post('projects/filterByOrder', { order: this.order })
				if (response.status === 200) {
					this.groups = response.data
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async findByName() {
			this.groups = []
			this.loading = true
			try {
				const empty = /^\s*$/
				if (!empty.test(this.name)) {
					const response = await Api.post('projects/findByName', { name: this.name })
					if (response.status === 200) {
						this.groups = response.data
					}
				} else {
					await this.findAll()
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		modalCreate() {
			if (this.permissionEnabled('projects', 'create')) {
				create(this, 'projects', Modal, 'New')
			}
		},
		modalEdit(id) {
			if (this.permissionEnabled('projects', 'edit')) {
				update(this, 'projects', id, Modal, 'Edit')
			}
		}
	},
	mounted() {
		eventHub.$on('edit-modal-project', obj => {
			update(this, 'projects', obj.id, Modal, 'Edit')
		})

		eventHub.$on('reload-projects', () => {
			this.findAll()
		})

		eventHub.$on('delete-command', obj => {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: this.$t('labels.warning'),
				message: '<span>' + this.$t('alerts.delete.confirmation') + '<span>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: this.$t('buttons.no'),
				confirmText: this.$t('buttons.yes'),
				onConfirm: async () => {
					try {
						const response = await Api.delete(`projects/destroy/${obj.id}`)
						const { status } = response
						if (status === 200) {
							successToast(this.$t('alerts.delete.success', [this.$tc('menu.projects')]))
							this.findAll()
						}
					} catch (e) {
						console.log(e)
						errorToast(this.$t('alerts.delete.error', [this.$tc('menu.projects')]))
					}
				}
			})
		})

		eventHub.$on('deploy-project', async (obj) => {
			try {
				const response = await Api.post('projects/deploy', { id: obj.id })
				if (response.status === 200) {
					this.findAll()
				}
			} catch (e) {
				console.log(e)
			}
		})

		eventHub.$on('select-env-project', async (obj) => {
			try {
				const response = await Api.get(`projects/listEnvs/${obj.id}`)
				if (response.status === 200) {
					ModalEnv.open({
						component: SelectEnv,
						scroll: 'keep',
						customClass: 'is-sm',
						trapFocus: true,
						props: {
							id: obj.id,
							envs: response.data
						}
					})
				}
			} catch (e) {
				console.log(e)
			}
		})

		eventHub.$on('edit-env-project', async (obj) => {
			let group = this.groups.find((g) => {
				return g.projects.find(p => p.id == obj.id)
			})

			let project = group.projects.find(p => p.id == obj.id)

			ModalEnv.open({
				component: EditorEnv,
				scroll: 'keep',
				customClass: 'is-sm',
				trapFocus: true,
				props: {
					id: obj.id,
					file: obj.file,
					groupName: group.name,
					projectName: project.name
				}
			})
		})

		this.findAll()
    },
	destroyed() {
		eventHub.$off('edit-modal-project')
		eventHub.$off('reload-project')
		eventHub.$off('delete-project')
		eventHub.$off('deploy-project')
		eventHub.$off('select-env-project')
		eventHub.$off('edit-env-project')
	},
	computed: {
		...mapGetters('user', ['permissionEnabled']),
	}
}
</script>
